<template>
  <!-- start page title -->
  <div class="row mb-3">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <div class="col-6 d-flex align-items-center">
          <h4 class="mb-0 me-4">{{ title }}</h4>
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>

        <div>
          <!--  <b-breadcrumb :items="items" class="m-0"></b-breadcrumb> -->
          <div v-if="moda_title">
            <!-- <b-button
              v-if="this.$route.name != 'players-index' && isAdmin"
              id="show-btn"
              variant="primary"
              @click="openModal"
              >Agregar</b-button
            > -->

            <vs-button v-if="isAdmin" primary @click="openModal">{{
              $t('helpers.add')
            }}</vs-button>

            <b-modal
              id="bv-modal-example"
              v-model="modal"
              size="lg"
              hide-footer
            >
              <template #modal-title>
                {{ moda_title }}
              </template>
              <slot> </slot>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    moda_title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  methods: {
    openModal() {
      this.modal = true;
    },
  },
};
</script>
